import React from "react"
import { Link } from "gatsby"
import portfolio from "../content/portfolio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostLink from "../components/post-link"

const IndexPage = ({ data }) => {
  const { edges } = data.allMarkdownRemark
  return (
    <Layout className="home">
      <SEO title="Home" />
      <h2 className="blurb">
        Full stack software engineer developing websites and applications with
        JavaScript, Node.js, React, React Native, and Swift.
      </h2>
      <section className="portfolio">
        <div className="portfolio-items">
          {portfolio.map(item => {
            return (
              <Link className="portfolio-item" to={item.link}>
                <img alt="" src={item.image} />
                <h3>{item.name}</h3>
                <p>{item.description}</p>
              </Link>
            )
          })}
        </div>
      </section>
      <section>
        <h1>Recent blog posts</h1>
        {edges.map(edge => (
          <PostLink key={edge.node.id} post={edge.node} />
        ))}
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 2
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 450)
          frontmatter {
            date(formatString: "D MMMM, YYYY")
            slug
            title
          }
        }
      }
    }
  }
`

export default IndexPage
