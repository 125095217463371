
import awardstackImage from "../images/awardstack/icon_color.svg";
import simpleScalesImage from "../images/simple-scales/icon_color.svg";
import neutronImage from "../images/neutron/icon_color.svg";

export default [
    {
        name: "Awardstack",
        link: "/portfolio/awardstack",
        image: awardstackImage,
        description: `Built from the ground up to host, manage, and judge events and competitions`
    },
    {
        name: "Simple Scales",
        link: "/portfolio/simple-scales",
        image: simpleScalesImage,
        description: `A simple way to update your weight using the native HealthKit API.`
    },
    {
        name: "Neutron",
        link: "/portfolio/neutron",
        image: neutronImage,
        description: `A Sass framework created for making flexible, clear, and semantic layouts.`
    },
]
